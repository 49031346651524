@import "variables.scss";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


#copyActions { 
  visibility: hidden;  
}

tr:hover #copyActions {
  visibility: visible;
}

.MuiCard-root:hover #copyActions {
  visibility: visible;
}

// RTE Styling 
.sun-editor {
  border: 1px solid transparent !important;
  background-color: transparent !important;
}

.sun-editor .se-toolbar {
  border: none !important;
  background-color: transparent !important;
  margin: 0;
  outline: 1px solid transparent !important;
  z-index: auto !important;
}

.sun-editor .se-btn {
  border: none !important;
  height: 40px !important;
  width: 40px !important;
  background: transparent !important;
}

.sun-editor .se-wrapper .se-wrapper-wysiwyg {
  border: 1px solid #2f7a8a !important;
  min-height: 50px !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif !important;
  font-size: 18px !important;
  line-height: 25px !important;
  font-weight: 500 !important;
}

.sun-editor .se-list-layer .se-selector-color {
  max-width: 115px !important;
}

.sun-editor .se-list-layer .se-selector-color .se-color-pallet li {
  margin: 3px !important;
  border: #ccc solid 1px;
}

.sun-editor-editable p {
  margin: 0px !important;
}

.se-submenu-form-group {
  display: none !important;
}

.sun-editor .se-list-layer {
  border-radius: 0px !important;
}