.App-logo { height: 40vmin;}

.App-header {
  background-color: #282c34; min-height: 10vh; display: flex;
  flex-direction: column; align-items: center; justify-content: center;
  font-size: calc(10px + 2vmin); color: white;
}

@font-face {
  font-family: 'RobotoLight';
  src: url('../Fonts/Roboto-Light.ttf') format('truetype');
  fontWeight: 'normal';
  fontStyle: 'normal';
}
.Spinner{ position: "fixed"; top: "50%"; left: "50%"; }